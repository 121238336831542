import { Table, Tag, Button, Popconfirm, Modal } from 'antd';
import React, { useContext, useState, useEffect } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import authContext from '../context/auth/authContext';
import { PUBLIC_API_URL } from '../config';
import AddVersion from './AddVersion';

const VersionTable = () => {
    const [version, setVersion] = useState(null);
    const [visible, setVisible] = useState(false);
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);

    const auth = useContext(authContext);
    const { isAuthenticated } = auth;

    const deleteVersion = async (id, fileName) => {
        const response = await axios.delete(`${PUBLIC_API_URL}/api/upload/delete/?key=${fileName}&bucket=dashing-updates`);
        axios.delete(`${PUBLIC_API_URL}/api/version/${id}`)
            .then(res => {
                window.location.reload()
            }).catch(err => {
                console.log(err)
            }
        )
    }

    const showModal = () => {
        setVisible(true)
    }
    const handleCancel = () => {
        setVisible(false)
    }

    const columns = [
        {
            title: 'Version Name',
            dataIndex: 'version',
            key: 'version',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Updating',
            dataIndex: 'updating',
            key: 'updating',
            render: (_, { companies }) => (
                <>
                    {companies.length===0?<Tag color="green">All</Tag>:
                        (companies.map((company) => {
                            return <Tag color="red">{company.name}</Tag>
                        }))
                    }
                </>
            ),
        },
        {
            title: 'Release Date',
            dataIndex: 'releaseDate',
            key: 'releaseDate',
            render: (text) => <span>{new Date(text).toLocaleString()}</span>,
        },
        {
            title: 'isApproved',
            key: 'isApproved',
            dataIndex: 'isApproved',
            render: (_, { isApproved }) => (
                <>
                    {isApproved ? <Tag color="green">Approved</Tag> : <Tag color="red">Not Approved</Tag>}
                </>
            ),

        },
        {
            title: 'Update Type',
            key: 'updateType',
            dataIndex: 'updateType',
            render: (_, { updateType }) => (
                <>
                    {updateType === 'major' ? <Tag color="red">Major</Tag> : null}
                    {updateType === 'minor' ? <Tag color="orange">Minor</Tag> : null}
                    {updateType === 'patch' ? <Tag color="green">Patch</Tag> : null}
                </>
            ),
        },
    ];

    isAuthenticated && columns.push.apply(columns, [
        {
            title: 'Force Update',
            key: 'forceUpdate',
            dataIndex: 'forceUpdate',
            render: (_, { forceUpdate }) => (
                <>
                    {forceUpdate ? <Tag color="red">Yes</Tag> : <Tag color="green">No</Tag>}
                </>
            ),
        }, {
            title: 'Update',
            dataIndex: 'update',
            key: 'update',
            render: (_, data) =>
                <Button type="primary" size={"middle"} color={'green'} onClick={
                    () => {
                        setVersion(data)
                        showModal()
                    }
                }> Update</Button>
            ,
        }, {
            title: 'Functions',
            dataIndex: 'file',
            key: 'file',
            render: (_, data) => <Popconfirm
                title="Are you sure to delete this version?"
                onConfirm={() => {
                    deleteVersion(data._id, data.file)
                }}
                onCancel={() => {
                }}
                okText="Yes"
                cancelText="No"
            >
                <Button type="danger" shape="circle" icon={<DeleteOutlined />} size={"small"} color={'green'} onClick={() => {

                }} />
            </Popconfirm>
        }
    ]);

    const fetchData = async (page, limit) => {
        try {
            const response = await axios.get(
                `${PUBLIC_API_URL}/api/version/getAll?page=${page}&limit=${limit}`
            );
            setData(response.data.data);
            setTotal(response.data.total); // assuming the total count is in the response
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData(currentPage||1, pageSize||10);
    }, [currentPage, pageSize,auth]);

    const handleTableChange = (pagination) => {
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
    };

    return (
        <div className={'table-responsive table-res'}>
            <Modal
                style={{
                    top: 20,
                }}
                title="Add new Version"
                visible={visible}
                onCancel={handleCancel}
                footer={false}
                width={720}
            >
                <AddVersion version={version} closeModal={handleCancel} refetch={fetchData} />
            </Modal>
            <Table
                columns={columns}
                dataSource={data}
                onChange={handleTableChange}
                pagination={{
                    current: currentPage,
                    pageSize: pageSize,
                    total: total,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100'],
                }}
            />
        </div>
    );
};

export default VersionTable;
