import {InboxOutlined} from '@ant-design/icons';
import {
    Button,
    Input,
    Form,
    Select,
    Switch,
    Upload, notification
} from 'antd';
import 'react-alert-confirm/dist/index.css';

import alertConfirm from 'react-alert-confirm';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {PUBLIC_API_URL} from "../config";
import useAxios from "axios-hooks";

alertConfirm.config({
    lang: 'en',
    zIndex: 10000024,
    okText: 'YES',
    cancelText: 'NO',
});
const {Option} = Select;


const formItemLayout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 14,
    },
};

const normFile = (e) => {
    // console.log('Upload event:', e);

    if (Array.isArray(e)) {
        return e;
    }

    return e?.fileList;
};

const UpdateReports = ({fileName,closeModal}) => {
    useEffect(() => {
        const handler = (e) => {
            if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
                e.stopImmediatePropagation();
            }
        };
        document.addEventListener("focusin", handler);
        return () => document.removeEventListener("focusin", handler);
    }, []);


    const [file, setFile] = useState(null)
    const [form] = Form.useForm();
    useEffect(() => {
        if (fileName) {
            const company = fileName.company;
            form.setFieldsValue({
                reportFile: fileName.fileName,
                updateType: company ? 'specific' : 'standard',
                companyName: company

            })
        }
    }, [fileName])
    const onFinish = async (values) => {
        if (!file) {
            notification['error']({
                message: 'Error',
                description:
                    'Please select a file',
            });
            return
        }

        const formData = new FormData();
        // put values in formData
        formData.append('file', file);
        formData.append('updateType', values?.updateType);
        formData.append('description', values?.description);
        formData.append('reportFile', values?.reportFile);
        formData.append('companyName', values?.companyName);
        //post request to upload file

        try {
            const res = await axios.put(`${PUBLIC_API_URL}/api/reports`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })

            //    create notification
            if (res?.data?.success) {
                const report = res?.data?.data
                notification['success']({
                    message: 'Success',
                    description:
                        `Report ${report?.fileName} has been updated successfully to version ${report?.version}`,
                });
                closeModal();
                form.resetFields();
            }
        } catch (e) {
            notification['error']({
                message: 'Error',
                description:
                e.response.data.error,
            });
        }


    };
    const UpdateType = Form.useWatch('updateType', form);
    const companyName = Form.useWatch('companyName', form);
    const reportFile = Form.useWatch('reportFile', form);
    const [{data, loading, error}, refetch] = useAxios(
        `${PUBLIC_API_URL}/api/company?select=name&limit=100`
    )
    const [reports] = useAxios(
        `${PUBLIC_API_URL}/api/reports/filenames/${companyName ? companyName : 'standard'}`
    )


    return (
        <>
            {/*width 100%*/}

            <Form
                name="validate_other"
                {...formItemLayout}
                onFinish={onFinish}
                layout={'vertical'}
                form={form}
            >


                <Form.Item
                    name="updateType"
                    label="Please select update type"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please select the type of update',
                        },
                    ]}
                >
                    <Select placeholder="Select type of reports update">
                        <Option value="standard">Standard</Option>
                        <Option value="specific">By Specific Company</Option>
                    </Select>
                </Form.Item>
                {String(UpdateType) === 'specific' &&
                    <Form.Item
                        name="companyName"
                        label="Please select company name"
                        hasFeedback
                        rules={[
                            {
                                required: String(UpdateType) === 'specific',
                                message: 'Please select the company Name',
                            },
                        ]}
                    >
                        <Select placeholder="Please select the company Name" disabled={loading} showSearch={true} onChange={(value) => {
                        }
                        }>
                            {data?.data?.map((company, index) => <Option key={index} value={company._id}>{company.name}</Option>)}
                        </Select>
                    </Form.Item>
                }
                {(String(UpdateType) === 'standard' || String(companyName) !== undefined) &&
                    <Form.Item
                        name="reportFile"
                        label="Please select report file that you want to update"
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please select report file that you want to update',
                            },
                        ]}
                    >
                        <Select placeholder="Select report file" disabled={loading} showSearch={true}>
                            {reports?.data?.data?.map((report, index) => <Option key={index} value={report.fileName}>{report.fileName}</Option>)}
                        </Select>
                    </Form.Item>
                }
                <Form.Item
                    label="Please write short changes"
                    name="description"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the description of the report update',
                        },
                    ]}
                >
                    <Input.TextArea/>
                </Form.Item>


                <Form.Item label="Upload the update file">
                    <Form.Item name="dragger" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                        <Upload.Dragger name="file" showUploadList={false} multiple={false}
                                        beforeUpload={file => {
                                            //get file name
                                            if (file.name === reportFile) {
                                                setFile(file)
                                                notification.success({
                                                    message: 'File uploaded successfully',
                                                })
                                            } else {


                                                alertConfirm({
                                                    title: 'OH NO!',
                                                    content: 'oh, no, The file name seems different from the file chosen.Are you sure, this is a correct file?',
                                                    onCancel: () => {
                                                        notification.warn({
                                                            message: 'File upload cancelled',
                                                        })
                                                    },
                                                    onOk: () => {
                                                        setFile(file)
                                                        notification.success({
                                                            message: 'File uploaded successfully',
                                                        })

                                                    }

                                                })
                                            }
                                            return false;


                                        }}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined/>
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        </Upload.Dragger>
                    </Form.Item>
                </Form.Item>


                <Form.Item
                    wrapperCol={{
                        span: 12,
                        offset: 6,
                    }}
                >

                    <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                        Update Report
                    </Button>
                </Form.Item>
            </Form>

        </>

    );
};

export default UpdateReports;