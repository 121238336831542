import React, {useEffect, useState} from 'react';
import {Button, Form, Input, List, notification, Popconfirm, Checkbox, Tooltip} from 'antd';
import {PlusOutlined, MinusCircleOutlined, CopyOutlined,BookOutlined} from '@ant-design/icons';
import UploadImages from "./UploadImages";
import axios from "axios";
import {PUBLIC_API_URL} from "../config";

function PasteOutlined() {
    return null;
}

const ChangeLogComponent = ({version,closeModal,refetch}) => {

    const [images,setImages]=useState([])
    const [bugFixes, setBugFixes] = useState([]);
    const [improvements, setImprovements] = useState([]);
    const [newFeatures, setNewFeatures] = useState([]);
    const [id,setId]=useState(1)
    const [isApproved,setIsApproved]=useState(false)
    const [companies,setCompanies]=useState([])


    useEffect(() => {
        setBugFixes(version.bugFixes);
        setCompanies(version.companies);
        setImprovements(version.improvements);
        setNewFeatures(version.newFeatures);
        const versionId = version.file;
        setId(versionId.split("_")[0])
        setIsApproved(version.isApproved)
        setImages(
            version.attachments.map((file)=>({name:file}))
        )
    }, [version])

    const addItem = (type) => {
        if (type === 'bugFix') {
            setBugFixes([...bugFixes, '']);
        } else if (type === 'improvement') {
            setImprovements([...improvements, '']);
        } else if (type === 'newFeature') {
            setNewFeatures([...newFeatures, '']);
        }
    };

    const deleteItem = (type, index) => {
        if (type === 'bugFix') {
            setBugFixes(bugFixes.filter((_, i) => i !== index));
        } else if (type === 'improvement') {
            setImprovements(improvements.filter((_, i) => i !== index));
        } else if (type === 'newFeature') {
            setNewFeatures(newFeatures.filter((_, i) => i !== index));
        }
    };


    const copyToClipboard = () => {
        const data = {
            bugFixes,
            improvements,
            newFeatures
        };
        navigator.clipboard.writeText(JSON.stringify(data));
        notification.success({
            message: 'Data copied to clipboard!',
        });
    };
    const pasteFromClipboard = async () => {
        try {
            const clipboardData = await navigator.clipboard.readText();
            const parsedData = JSON.parse(clipboardData);

            // Validate the parsed data
            if (
                typeof parsedData === 'object' &&
                Array.isArray(parsedData.bugFixes) &&
                Array.isArray(parsedData.improvements) &&
                Array.isArray(parsedData.newFeatures)
            ) {
                setBugFixes(parsedData.bugFixes);
                setImprovements(parsedData.improvements);
                setNewFeatures(parsedData.newFeatures);
                notification.success({
                    message: 'Data pasted successfully!',
                });
            } else {
                notification.error({
                    message: 'Failed to paste data! Ensure the copied data has the correct format.',
                });
            }
        } catch (error) {
            notification.error({
                message: 'Failed to paste data! Ensure the copied data has the correct format.',
            });
        }
    };

    const updateItem = (type, index, value) => {
        if (type === 'bugFix') {
            setBugFixes(bugFixes.map((item, i) => (i === index ? value : item)));
        } else if (type === 'improvement') {
            setImprovements(improvements.map((item, i) => (i === index ? value : item)));
        } else if (type === 'newFeature') {
            setNewFeatures(newFeatures.map((item, i) => (i === index ? value : item)));
        }
    };
    const onSubmit = async () => {
        try {
            const data = {
                bugFixes,
                improvements,
                newFeatures,
                attachments: images.map((image) => image.name),
                isApproved
            };

            await axios.put(
                `${PUBLIC_API_URL}/api/version/${version._id}`,
                data,
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            );
            closeModal()
            refetch()

            notification.success({
                message: 'Version Updated Successfully',
            });


        } catch (e) {
            console.error(e);
        }
    };
    const renderList = (type, data) => (
        <List
            emptyText={`No ${type}s added yet`}
            dataSource={data}
            renderItem={(item, index) => (
                <List.Item style={{ width: '100%' }}>
                    <Form.Item style={{ width: '100%' }}>
                        <Input
                            value={item}
                            onChange={(e) => updateItem(type, index, e.target.value)}
                            style={{ marginRight: 8, width: '100%' }}
                        />
                    </Form.Item>
                    <Popconfirm
                        title="Are you sure you want to delete this item?"
                        onConfirm={() => deleteItem(type, index)}
                    >
                        <MinusCircleOutlined className="dynamic-delete-button mb-4 danger-button" />

                    </Popconfirm>
                </List.Item>
            )}
        />

    );

    return (
        <Form layout="vertical">

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Tooltip title="Copy to Clipboard">
                    <Button icon={<CopyOutlined />} onClick={copyToClipboard}> Copy</Button>
                </Tooltip>
                <Popconfirm
                    title="Are you sure you want to paste from clipboard?"
                    onConfirm={pasteFromClipboard}
                >

                    <Tooltip title="Paste from Clipboard">
                        <Button icon={<BookOutlined />}> Paste</Button>
                    </Tooltip>
                </Popconfirm>

            </div>
            {
                companies.length>0?(
                    <>
                        <h3>Updating</h3>
                        <li>{companies.map((company)=>company.name).join(", ")}</li>
                    </>
                ):<h3>Updating all companies</h3>
            }
            <h3>Bug Fixes</h3>
            {renderList('bugFix', bugFixes)}
            <Button onClick={() => addItem('bugFix')} type="primary" icon={<PlusOutlined />} style={{ width: '100%' }} danger={true} >
                Add Bug Fix
            </Button>
            <h3>Improvements</h3>
            {renderList('improvement', improvements)}
            <Button onClick={() => addItem('improvement')} type="dashed" icon={<PlusOutlined />} style={{ width: '100%', background:'#ff8800',color:"white"}}>
                Add Improvement
            </Button>

            <h3>New Features</h3>
            {renderList('newFeature', newFeatures)}
            <Button onClick={() => addItem('newFeature')} type="dashed" icon={<PlusOutlined />} style={{ width: '100%', background:'#00ff6e'}}>
                Add New Feature
            </Button>
            <UploadImages imageId={id} images={images} setImages={setImages}/>


            <Form.Item>
                <Checkbox
                    checked={isApproved}
                    onChange={(e) => setIsApproved(e.target.checked)}
                    style={{ marginTop: 16 }}
                >
                    Check if you want to approve this version
                </Checkbox>
            </Form.Item>
            <Button onClick={onSubmit} type="primary" style={{ width: '100%', marginTop: 16 }}>
                Submit
            </Button>
        </Form>
    );
};
export default ChangeLogComponent;
