import React, {useContext, useState} from 'react';
import DDS from './Dashing Distribution Software.svg'
import authContext from "../context/auth/authContext";
import {Modal} from "antd";
import AddVersion from "./AddVersion";
import UpdateReports from "./UpdateReports";
import {Link} from "react-router-dom";

const Navbar = () => {
    const auth = useContext(authContext);
    const {isAuthenticated,logout} = auth;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const[isReportsModalVisible,setIsReportsModalVisible]=useState(false)
    const showModal = () => {
        setIsModalVisible(true);
    };const showReportsModal = () => {
        setIsReportsModalVisible(true);
    };


    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const handleReportsCancel = () => {
        setIsReportsModalVisible(false);
    }
    return (
        <nav className="navbar navbar-dark navbar-expand-md fixed-top navbar-shrink py-3" id="mainNav">
            <div className="container"><a className="navbar-brand d-flex align-items-center" href="/">
                <span className=" d-flex justify-content-center align-items-center me-2 bs-icon">
                    <img src={DDS} alt="" height={'70px'}/></span></a>
                <button data-bs-toggle="collapse" className="navbar-toggler" data-bs-target="#navcol-1"><span className="visually-hidden">Toggle navigation</span><span className="navbar-toggler-icon"></span></button>
                <div className="collapse navbar-collapse" id="navcol-1">
                    <ul className="navbar-nav mx-auto">
                        <li className="nav-item"><a className="nav-link active" href="/">Home</a></li>
                    </ul>
                    <Link to={'/updater'} className="btn btn-primary shadow mb-1" role="button">Update Version</Link>
                    <Link to={'/reports'} className="btn btn-primary shadow mb-1" role="button">Update reports</Link>
                    {!isAuthenticated && (<a className="btn btn-primary shadow" role="button" href="/">Login</a>)}
                    {isAuthenticated&&<a className="btn btn-primary shadow" role="button" onClick={()=>{
                        logout();
                        window.location.reload();
                    }}>Logout</a>}
                </div>

            </div>

            <Modal style={{
                top: 20,
            }} title="Reports" visible={isReportsModalVisible} onCancel={handleReportsCancel} footer={false} width={720}>
                <UpdateReports />
            </Modal>
        </nav>
    );
};

export default Navbar;
