import React, {useState, useCallback, useEffect} from "react";
import axios from "axios";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import {Button, notification} from "antd";
import { useDropzone } from "react-dropzone";
import { PUBLIC_API_URL } from "../config";

const ImageUploader = ({imageId,setImages,images}) => {
    const [files, setFiles] = useState([...images]);
    useEffect(() => {
        setFiles([...images])
    }, [images])

    const onDrop = useCallback((acceptedFiles) => {

        setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    }, []);


    const handleRemove = async (index) => {
        try {
            const newFiles = [...files];
            const removedFile = newFiles.splice(index, 1)[0];
            if(!removedFile.path) {

                const response = await axios.delete(`${PUBLIC_API_URL}/api/upload/delete/?key=release_images/${removedFile.name}&bucket=dashing-public`);

                setFiles(newFiles);
                setImages(newFiles);
            }
        } catch (err) {
            console.error(err);
        }
    };
    const handleSubmit = async () => {

        try {
            const uploadedImages = [];
            const formData = new FormData();

            files.forEach((file) => {
                if(file.path) {
                    formData.append("file", file);
                }
                else{
                    if(file.name){
                        uploadedImages.push(
                            {
                                name:file.name
                            }
                        )
                    }
                }
            });
            const {data}=await axios.post(`${PUBLIC_API_URL}/api/upload/${imageId}`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            });
            setImages([...data.data.map((object)=>({name:object.key.split('/')[1]})),...uploadedImages])
            setFiles([])

            notification.success({
                message: 'Images Uploaded Successfully',
            });
        } catch (error) {
            console.error(error);
            alert("Upload Failed!");
        }
    };
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'image/jpeg': ['.jpeg', '.png', '.jpg', '.gif', '.svg'],
            'video/*': ['.mp4', '.mov', '.avi', '.mkv', '.flv', '.wmv', '.webm', '.m4v', '.3gp', '.ogv'],
            'application/pdf': '.pdf',
        },
        multiple: true,
    });

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "20px",
            }}
        >
            <div
                {...getRootProps()}
                style={{
                    width: "100%",
                    height: "200px",
                    border: "2px dashed #ccc",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    marginBottom: "24px",
                }}
            >
                <input {...getInputProps()} />
                <UploadOutlined style={{ fontSize: "32px" }} />
                <p style={{ marginLeft: "16px", fontSize: "16px" }}>
                    Drag and drop images/videos here or click to select
                </p>
            </div>
            <div style={{ width: "100%" }}>
                {files.map((file, index) => (
                    <div
                        key={file.name}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "12px",
                            border: "1px solid #ccc",
                            marginBottom: "16px",
                        }}
                    >
                        <p style={{ marginRight: "16px" }}>{file.name}</p>
                        <DeleteOutlined
                            style={{ fontSize: "24px", color: "#f00", cursor: "pointer" }}
                            onClick={() => handleRemove(index)}
                        />
                    </div>
                ))}
            </div>
            <Button
                type="primary"
                onClick={handleSubmit}
                disabled={files.length === 0}
            >
                Upload
            </Button>
        </div>
    );
};

export default ImageUploader;
