import React, {useContext, useEffect} from 'react';
import axios from "axios";
import {PUBLIC_API_URL} from "../config";
import authContext from "../context/auth/authContext";
import {notification} from "antd";
import {useNavigate} from 'react-router-dom';
const Login = () => {
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    let navigate = useNavigate();

    //context
    const auth = useContext(authContext);
    const {login, loadUser, isAuthenticated} = auth;
    useEffect(() => {
        if(isAuthenticated){
            // push it to home page
            navigate('/updater')
        }
    }, [isAuthenticated])
    const onChangeEmail = (e) => {
        setEmail(e.target.value);
    }
    const onChangePassword = (e) => {
        setPassword(e.target.value);
    }
    const openNotification = () => {
        notification.open({
            message: 'Successfully Logged In',
            description:
                'You have successfully logged in to your account, now you can add,edit,delete versions',
        });
    };
    const loginCallback = async (e) => {

        e.preventDefault()
        const JSONConfig = {
            headers: {
                'Content-Type': 'application/json',
            },
        }
        try {
            const {data} = await axios.post(
                `${PUBLIC_API_URL}/api/auth/login`,
                {email: email, password: password},
                JSONConfig
            )
            login(data)
            localStorage.setItem('userInfo', JSON.stringify(data))
            localStorage.setItem('token', JSON.stringify(data.token))
            await loadUser()
            openNotification()

        } catch (error) {
            notification.open({
                message: 'Please check your credentials',
                description:
                    'You have entered wrong credentials, please check your email and password',
            });
        }
    };
    return (


        <section className="py-5 mt-5">
            <div className="container py-5">
                <div className="row mb-4 mb-lg-5">
                    <div className="col-md-8 col-xl-6 text-center mx-auto">
                        <p className="fw-bold text-success mb-2">Login</p>
                        <h2 className="fw-bold text-light">Welcome</h2>
                    </div>
                </div>
                <div className="row d-flex justify-content-center">
                    <div className="col-md-6 col-xl-6">
                        <div className="card">
                            <div className="card-body text-center d-flex flex-column align-items-center">
                                <div className="bs-icon-xl bs-icon-circle bs-icon-primary shadow bs-icon my-4">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-person">
                                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"></path>
                                    </svg>
                                </div>
                                <form onSubmit={loginCallback}>
                                    <div className="mb-3"><input className="form-control" type="email" name="email" placeholder="Email" onChange={onChangeEmail}/></div>
                                    <div className="mb-3"><input className="form-control" type="password" name="password" placeholder="Password" onChange={onChangePassword}/></div>
                                    <div className="mb-3">
                                        <button className="btn btn-primary shadow d-block w-100" type="submit">Login</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Login;
