import AWS from 'aws-sdk';
import {
    ChonkyActions,
    FileBrowser,
    FileList,
    FileNavbar,
    FileToolbar,
    setChonkyDefaults,
} from 'chonky';
import { ChonkyIconFA } from 'chonky-icon-fontawesome';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import UpdateReports from "./UpdateReports";
import {Modal} from "antd";
import axios from "axios";
import {PUBLIC_API_URL} from "../config";
import AddReports from "./addReports";
import authContext from "../context/auth/authContext";
setChonkyDefaults({ iconComponent: ChonkyIconFA });
// The AWS credentials below only have read-only access to the Chonky demo bucket.
// You will need to create custom credentials for your bucket.
const BUCKET_NAME = 'dashing-reports';
const BUCKET_REGION = 'eu-west-2';
const ACCESS_KEY_ID = 'AKIA3DOZVJIGJIVPKCIB';
const SECRET_ACCESS_KEY = 'j9CwetDpQfJCYl9Ghbex+aJDv8PiMC1R3uskR0Q+';

AWS.config.update({
    region: BUCKET_REGION,
    accessKeyId: ACCESS_KEY_ID,
    secretAccessKey: SECRET_ACCESS_KEY,
});
const s3 = new AWS.S3({
    region: BUCKET_REGION,
    accessKeyId: ACCESS_KEY_ID,
    secretAccessKey: SECRET_ACCESS_KEY,
});

const fetchS3BucketContents = (bucket, prefix)=> {
    return s3
        .listObjects({
            Bucket: bucket,
            Delimiter: '/',
            Prefix: prefix !== '/' ? prefix : '',
        })
        .promise()
        .then((response) => {
            const chonkyFiles = [];
            const s3Objects = response.Contents;
            const s3Prefixes = response.CommonPrefixes;
            if (s3Objects) {
                chonkyFiles.push(
                    ...s3Objects.map(
                        (object) => ({
                    id: object.Key,
                    name:object.Key ,
                modDate: object.LastModified,
                    size: object.Size,

            })
            )
            );
            }

            if (s3Prefixes) {
                chonkyFiles.push(
                    ...s3Prefixes.map(
                        (prefix) => ({
                    id: prefix.Prefix,
                    name: prefix.Prefix,
                isDir: true,
            })));}

            return chonkyFiles;
        });
};

const storyName = 'AWS S3 Browser';
export const S3Browser = () => {
    const [error, setError] = useState(null);
    const [folderPrefix, setKeyPrefix] = useState('/');
    const [files, setFiles] = useState([]);
    const [isUpdateOpen, setIsUpdateOpen] = useState(false);
    const [isAddOpen, setIsAddOpen] = useState(false);
    const [currentFile, setCurrentFile] = useState(null);
    const auth = useContext(authContext);
    const {isAuthenticated} = auth;
    useEffect(() => {
        if (!isAuthenticated) {
            window.location.href = '/';
        }
    },[isAuthenticated]);
    useEffect(() => {
        fetchS3BucketContents(BUCKET_NAME, folderPrefix)
            .then(setFiles)
            .catch((error) => setError(error.message));
    }, [folderPrefix, setFiles]);

    const folderChain = React.useMemo(() => {
        let folderChain;
        if (folderPrefix === '/') {
            folderChain = [];
        } else {
            let currentPrefix = '';
            folderChain = folderPrefix
                .replace(/\/*$/, '')
                .split('/')
                .map(
                    (prefixPart) => {
                currentPrefix = currentPrefix
                    ? (currentPrefix+ prefixPart)
                    : prefixPart;
                return {
                    id: currentPrefix,
                    name: prefixPart,
                    isDir: true,
                };
            }
        );
        }
        folderChain.unshift({
            id: '/',
            name: BUCKET_NAME,
            isDir: true,
        });
        return folderChain;
    }, [folderPrefix]);

    const handleFileAction = useCallback(
        async (data) => {
            if (data.id === ChonkyActions.OpenFiles.id) {

                if (data.payload.files && data.payload.files.length !== 1) return;
                if (!data.payload.targetFile || !data.payload.targetFile.isDir) {
                    //make a axios post call to get the file
                    const res= await axios.post(`${PUBLIC_API_URL}/api/reports/s3File/`, {fileName: data.payload.targetFile.name},
                        {headers: {'Content-Type': 'application/json'}});

                    setCurrentFile(res.data);
                    setIsUpdateOpen(true);
                    return;
                }

                const newPrefix = `${data.payload.targetFile.id.replace(/\/*$/, '')}/`;
                setKeyPrefix(newPrefix);
            }
        },
        [setKeyPrefix]
    );

    return (
        <div className="story-wrapper" style={{marginTop:'120px'}}>
            <div style={{ height: "calc(100vh - 160px)"}}>
                <a className="btn btn-primary shadow mb-2" role="button" onClick={()=>{
                    setIsUpdateOpen(true);
                    setCurrentFile(null);
                }}>Update a report</a>
                <a className="btn btn-primary shadow mb-2" role="button" onClick={()=>{
                    setIsAddOpen(true);
                }} >Add a Report</a>
                <FileBrowser
                    darkMode={true}
                    instanceId={storyName}
                    files={files}
                    disableDragAndDrop={true}
                    disableDragAndDropProvider={true}
                    folderChain={folderChain}
                    onFileAction={handleFileAction}
                >
                    <FileNavbar />
                    <FileToolbar/>
                    <FileList />

                </FileBrowser>
            </div>
            <Modal title="Update Reports" visible={isUpdateOpen} onCancel={()=>{setIsUpdateOpen(false)}} footer={false} width={720}>
                <UpdateReports fileName={currentFile} closeModal={()=>{
                    setIsUpdateOpen(false);
                }} />
            </Modal>
            <Modal title="Add Reports" visible={isAddOpen} onCancel={()=>{setIsAddOpen(false)}} footer={false} width={720}>
                <AddReports closeModal={()=>{setIsAddOpen(false)}} />
            </Modal>
        </div>
    );
};
export default S3Browser;
